$primary: #094b9b;
$secondary: #ec4067;
$light-primary: #0a58b6;
$light-blue: #00d4ff;
$white: #ffffff;
$dirty-white: #f8f8f8;
$light-gray: #cecece;
$gray: #808080;
$dark-gray: #4f4f4f;
$black: #000000;
$green: #1a741d;
$died_green: #7ecb81;
$red: #ff0000;
$died_red: #f16f6f;
