.home {
    padding: 1rem;

    .home__progress-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

        .home__progress-bar {
            position: relative;
            width: 100%;
            max-width: 600px;
            height: 50px;
            border: 2px solid $black;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .home__progress-bar-fill {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0;
                top: 0px;
                height: 100%;
                background-color: #5225bd;
                border-radius: 10px;
                animation: g 2500ms infinite ease-in-out;
                z-index: 2;

                .home__progress-bar-liquid {
                    z-index: 1;
                    width: 70px;
                    height: 70px;
                    animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
                    position: absolute;
                    right: -5px;
                    top: -10px;
                    background-color: #5225bd;
                    border-radius: 40%;
                }
            }

            .home__progress-bar-percentage {
                z-index: 2;
                color: $white;
                font-weight: bold;
            }
        }

        .home__progress-percentage {
            // align-self: flex-start;
        }
    }
}

@keyframes g {
    0% {
        background-color: #5225bd;
    }

    50% {
        background-color: #607e9e;
    }

    100% {
        background-color: #5225bd;
    }
}

@keyframes r {
    from {
        transform: rotate(0deg);
    }

    from {
        transform: rotate(360deg);
    }
}