.wind {
    height: 100vh;
    background: url('../assets/images/mapimage.jpeg') center no-repeat;
    background-size: cover;

    .wind__background-dimmer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);

        .wind__row {
            display: flex;
            align-items: center;
            color: $white;

            .wind__compass {
                transition: 0.3s;

                .wind__compass-needle {
                    width: 7rem;
                    height: 7rem;
                    transform: rotateY(80deg) rotateZ(45deg);
                    background: rgb(255, 0, 0);
                    background: linear-gradient(
                        135deg,
                        rgba(255, 0, 0, 1) 0%,
                        rgba(232, 0, 0, 1) 50%,
                        rgba(223, 223, 223, 1) 50%,
                        rgba(255, 255, 255, 1) 100%
                    );
                }
            }

            .wind__direction {
                margin: 2rem;
            }
        }

        .wind__info-row {
            color: $white;
            text-align: center;
        }
    }
}
