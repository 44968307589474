.create-user {
    padding: 1rem;

    .create-user__title {
        margin: 3rem 0 2rem;
        text-align: center;
        color: $primary;
        font-size: 1.3rem;
    }

    .create-user__form-container {
        display: flex;
        margin: auto;

        .create-user__form {
            width: 100%;

            .create-user__form-group {
                display: flex;
                flex-direction: column;
                margin: 1rem 0;

                .create-user__form-label {
                    font-size: 0.9rem;
                    margin-bottom: 0.5rem;
                }

                .create-user__form-input {
                    padding: 0.5rem 1rem;
                    border-radius: 10px;
                    border: 2px solid $gray;

                    &:focus {
                        outline-color: $primary;
                    }
                }
            }
            .create-user__form-group--buttons {
                display: flex;
                margin: 1rem 0;

                .create-user__form-button {
                    cursor: pointer;
                    margin-top: 1rem;
                    padding: 0.3rem 1.5rem;
                    background-color: transparent;
                    color: $primary;
                    border: 2px solid $primary;
                    border-radius: 10px;
                    transition: 0.3s;

                    &:hover {
                        background-color: $primary;
                        color: $dirty-white;
                    }

                    &--secondary {
                        cursor: pointer;
                        margin-top: 1rem;
                        padding: 0.3rem 1.5rem;
                        background-color: transparent;
                        color: $secondary;
                        border: 2px solid $secondary;
                        border-radius: 10px;
                        transition: 0.3s;

                        &:hover {
                            background-color: $secondary;
                            color: $dirty-white;
                        }
                    }

                    &--green {
                        cursor: pointer;
                        margin-top: 1rem;
                        padding: 0.3rem 1.5rem;
                        background-color: transparent;
                        color: $green;
                        border: 2px solid $green;
                        border-radius: 10px;
                        transition: 0.3s;

                        &:hover {
                            background-color: $green;
                            color: $dirty-white;
                        }
                    }
                }
            }
        }
    }

    .create-user__container-message--success,
    .create-user__container-message--error {
        margin-top: 1rem;
        display: flex;
        justify-content: center;

        .create-user__message {
            width: 100%;
            padding: 0.5rem 1rem;
            text-align: center;
            border-radius: 10px;
        }
    }

    .create-user__container-message--error {
        .create-user__message {
            background-color: $died_red;
            border: 2px solid $red;
        }
    }

    .create-user__container-message--success {
        .create-user__message {
            background-color: $died_green;
            border: 2px solid $green;
        }
    }
}

@media only screen and (min-width: 768px) {
    .create-user {
        .create-user__form-container {
            max-width: 800px;

            .create-user__form-group--buttons {
                justify-content: right;

                .create-user__form-button {
                    width: fit-content;
                    margin-right: 1rem;

                    &:last-child {
                        margin-right: 0;
                    }

                    &--secondary {
                        width: fit-content;
                        margin-right: 1rem;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    &--green {
                        width: fit-content;
                        margin-right: 1rem;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .create-user__container-message--error,
        .create-user__container-message--success {
            .create-user__message {
                max-width: 800px;
            }
        }
    }
}
