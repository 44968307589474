@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;1,400&family=Poppins:ital,wght@0,400;0,600;0,700;1,400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Montserrat', 'Poppins', -apple-system, BlinkMacSystemFont,
        'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue', sans-serif;
    font-size: 16px;
}

a {
    text-decoration: none;
    color: inherit;
}

.body-wrapper {
    padding: 1rem;
}

// @media only screen and (min-width: 1441px) {
//     * {
//         font-size: 24px
//     }
// }

@import './abstracts/colors';
@import './error';
@import './loader';
@import './header';
@import './custom-table';
@import './login';
@import './home';
@import './home/general-status';
@import './home/workers-table';
@import './home/details-meeting-points';
@import './home/meeting-point-info';
@import './home/workers-on-meeting-point';
@import './home/configuration';
@import './home/meeting-point';
@import './gvir';
@import './wind';
@import './admin';
@import './admin/users/user-list';
@import './admin/users/create';
@import './admin/users/update';
@import './admin/meeting-points/meeting-points-list';
@import './admin/meeting-points/create';
@import './admin/meeting-points/update';
@import './footer';
