.login {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: linear-gradient(115deg, #020024, #094b9b 0, #00d4ff 64%);
    padding: 2rem;

    .login__project-name {
        margin-bottom: 2rem;

        .login__project-name-text {
            color: $white;
            font-size: 3rem;
            font-family: monospace;
            text-align: center;
            text-shadow: 3px 2px 10px rgba(0, 0, 0, 0.22);
        }
    }

    .login__form {
        width: 100%;

        .login__form-group {
            display: flex;
            flex-direction: column;
            margin: 1rem 0;

            .login__label {
                display: flex;
                color: $white;
                align-items: center;
                margin-bottom: 0.5rem;
                text-shadow: 3px 2px 10px rgba(0, 0, 0, 0.22);
            }

            .login__input {
                padding: 0.5rem;
                height: 2.5rem;
                border-radius: 200px;
                border: 0px solid $black;
                outline: none;
                box-shadow: 3px 14px 28px -13px rgba(0, 0, 0, 0.22);
                text-transform: lowercase;
            }
        }

        .login__submit-button {
            cursor: pointer;
            width: 100%;
            margin-top: 3rem;
            padding: 0.5rem;
            border: none;
            border-radius: 200px;
            color: $white;
            background-color: $secondary;
            font-weight: bold;
            box-shadow: 3px 14px 28px -13px rgba(0, 0, 0, 0.22);
        }
    }
}

@media only screen and (min-width: 768px) {
    .login {
        flex-direction: row;

        .login__project-name {
            margin: 0;

            .login__project-name-text {
                display: table-caption;
            }
        }

        .login__divider {
            margin: 0 3rem;
            width: 2px;
            background-color: #fff;
            height: 14rem;
        }

        .login__form {
            width: 40%;
            max-width: 395px;
        }
    }
}
