.user-list {
    .user-list__title {
        margin: 3rem 0 2rem;
        text-align: center;
        color: $primary;
        font-size: 1.5rem;
    }

    .user-list__actions {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0.8rem;

        .user-list__create-action {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: 1rem;
            padding: 0.3rem 0.6rem;
            background-color: $primary;
            color: $white;
            border: 2px solid $primary;
            border-radius: 10px;
            transition: 0.3s;
            justify-content: space-around;

            &:hover {
                background-color: $light-primary;
                border: 2px solid $light-primary;
            }

            .user-list__create-action-icon {
                margin-right: 0.4rem;
            }
        }

        .user-list__search {
            border: 2px solid $primary;
            border-radius: 10px 0 0 10px;
            padding: 0 0.5rem;

            &:focus {
                outline: none;
            }
        }
        .user-list__search-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0.5rem 0 0.4rem;
            background-color: $primary;
            color: $white;
            border: $primary;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

            .user-list__search-button-icon {
                font-size: 1.2rem;
            }
        }
    }

    .user-list__table-container {
        .user-list__table {
            display: flex;
            flex-direction: column;
            width: inherit;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0px 0px 30px -20px rgba(0, 0, 0, 0.75);

            .user-list__table-head {
                display: none;
            }

            .user-list__table-body {
                max-height: 500px;
                overflow-y: scroll;

                .user-list__table-body-row {
                    margin-bottom: 0.1rem;
                    padding: 1.5rem 1rem;
                    background-color: #f8f8f8;

                    .user-list__table-body-cell {
                        padding: 0.4rem 0;

                        &::before {
                            display: block;
                            content: attr(data-title);
                            margin-bottom: 0.3rem;
                            color: #808080;
                            font-size: 0.7rem;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }

                    .user-list__table-body-cell--flex {
                        display: flex;
                        padding: 0.4rem 0;

                        &::before {
                            display: block;
                            content: attr(data-title);
                            margin-bottom: 0.3rem;
                            color: #808080;
                            font-size: 0.7rem;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }

                    .user-list__table-body-button {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0.2rem;
                        border: none;
                        background-color: #e2e2e2;
                        border-radius: 4px;
                        margin: 0 1rem;

                        &:first-child {
                            margin: 0;
                        }

                        .user-list__table-body-button-icon--green {
                            font-size: 1.4rem;
                            color: $green;
                        }

                        .user-list__table-body-button-icon--red {
                            font-size: 1.4rem;
                            color: $red;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .user-list {
        .user-list__table-container {
            .user-list__table {
                .user-list__table-head {
                    display: flex;
                    background-color: $primary;
                    padding: 1.5rem 1rem;
                    color: $white;
                    font-weight: bold;

                    .user-list__table-head-cell {
                        padding: 0 0.2rem;
                        &:nth-child(1) {
                            width: 440px;
                            padding-left: 40px;
                        }

                        &:nth-child(2) {
                            width: 440px;
                        }

                        &:nth-child(3) {
                            width: 440px;
                        }

                        &:nth-child(4) {
                            width: 440px;
                        }

                        &:nth-child(5) {
                            width: 440px;
                        }

                        &:nth-child(6) {
                            width: 150px;
                            padding-right: 40px;
                        }
                    }
                }

                .user-list__table-body {
                    max-height: 300px;

                    .user-list__table-body-row {
                        display: flex;

                        .user-list__table-body-cell {
                            display: flex;
                            align-items: center;
                            padding: 0 0.2rem;

                            &::before {
                                display: none;
                            }

                            &:nth-child(1) {
                                width: 440px;
                                padding-left: 40px;
                            }

                            &:nth-child(2) {
                                width: 440px;
                            }

                            &:nth-child(3) {
                                width: 440px;
                            }

                            &:nth-child(4) {
                                width: 440px;
                            }

                            &:nth-child(5) {
                                width: 440px;
                            }

                            &:nth-child(6) {
                                width: 150px;
                                padding-right: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}
